@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Raleway'");
@import url("https://fonts.googleapis.com/css2?family=Roboto");

svg {
   vertical-align: baseline;
}

body,
html {
   margin: 0;
}

* {
   font-family: "Roboto" !important;
}

.ant-checkbox-inner {
   border-radius: 5px !important;
}
