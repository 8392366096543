$primaryColor: #e6eee3;
$secondaryColor: #eb5e28;
$primaryLight: #f1faee;
$secondaryLight: #db9c80;

.ant-model-content {
   border-radius: 0.5rem !important;
}

td.ant-table-cell-row-hover {
   background-color: $primaryColor !important;
}

thead.ant-table-thead > tr > th {
   background-color: $primaryColor;
}

tbody.ant-table-tbody > tr > td {
   background-color: $primaryLight;
}

.login-container {
   margin-right: 30px;
   border-radius: 10px;
   width: 300px;
   background-color: $primaryColor;
}

.login-label {
   color: $secondaryColor;
   font-size: 40px;
}

.secondary-button,
.secondary-button:focus {
   background-color: $secondaryColor !important;
   border-radius: 5px !important;
   height: 40px !important;
   font-size: 18px !important;
   color: white !important;
   font-weight: bold !important;
}

.secondary-button:hover {
   background-color: $secondaryColor !important;
   border-radius: 5px !important;
   height: 40px !important;
   font-size: 18px !important;
   color: white !important;
   font-weight: bold !important;
   opacity: 0.8 !important;
   border: none !important;
}

.primary-input {
   height: 40px;
   border-radius: 5px;
   font-size: 16px;
}

.sider {
   background-color: $primaryColor !important;
}

.main-container {
   background-color: $primaryLight;
}

.text-secondary {
   color: $secondaryColor;
   font-size: 16px;
}

.ant-input-affix-wrapper > input.ant-input {
   line-height: 0 !important;
}

.line-height-0 {
   line-height: 0 !important;
}

.side-item {
   display: inline-flex !important;
   align-items: center;
   justify-content: left;
   padding: 20px !important;
}

.selected-side-item {
   background-color: $secondaryColor !important;
   border-radius: 8px !important;
   height: 40px !important;
   font-size: 16px !important;
   color: white !important;
}

.selected-side-item:focus {
   background-color: $secondaryColor !important;
   border-radius: 8px !important;
   height: 40px !important;
   font-size: 16px !important;
   color: white !important;
   border-color: $secondaryColor !important;
}

.unselected-side-item:hover {
   background-color: $secondaryColor !important;
   border-radius: 8px !important;
   height: 40px !important;
   font-size: 16px !important;
   color: white !important;
}

.unselected-side-item {
   background-color: $primaryColor !important;
   border-radius: 8px !important;
   height: 40px !important;
   font-size: 16px !important;
   color: black !important;
}

.main-title {
   font-family: Raleway;
   font-style: normal;
   font-weight: 600;
   font-size: 24px;
   line-height: 100%;
}

.overview-card {
   border-radius: 10px;
   padding: 40px 35px 35px 35px;
}

.overview-card-title {
   font-family: Raleway;
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
}

.overview-card-value {
   font-family: Poppins;
   font-style: normal;
   font-weight: 600;
   font-size: 64px;
   line-height: 100%;
   margin-top: 17px;
}

.logout-link {
   color: $secondaryColor !important;
   font-family: Raleway !important;
   font-style: normal !important;
   font-weight: 500 !important;
   font-size: 16px !important;
   line-height: 22px !important;
   text-decoration: underline !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   transition: background-color 5000s ease-in-out 0s;
}
